import { createContext } from "react";
import {Device} from "../../utils/Device";

export const themes = {
  dark: "dark-mode",
  light: "",
};

export const ThemeContext = createContext({
  theme: Device.isDarkMode ? themes.light : themes.light,
  toggleTheme: (string: string) => {},
});
