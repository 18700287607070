/**
 * © Hosty
 */
import {detect} from "detect-browser";

export enum DeviceOS {
    WINDOWS = "win",
    MACOS = "darwin",
    LINUX = "linux",
    X11 = "unix",
    IOS = "ios",
    ANDROID = "android",
}

export enum Browser {
    SAFARI = "safari",
    FIREFOX = "firefox",
    CHROME = "chrome",
    EDGE = "edge",
    IE = "ie",
}

export enum DeviceTypes {
    Smartphone = "smartphone",
    Tablet = "tablet",
    Desktop = "desktop",
}

export enum DeviceOrientation {
    Landscape = "landscape",
    Portrait = "portrait",
}

enum DeviceBreakPoints {
    Smartphone = 768,
    Tablet = 1366,
}

const pointerEvents: { [k in "move"]?: MouseEvent | undefined } = {};

export class Device {
    public static get currentWidth() {
        return window.innerWidth || document.documentElement.clientWidth;
    }

    public static get currentHeight() {
        return window.innerHeight || document.documentElement.clientHeight;
    }

    public static get currentDevice(): DeviceTypes | undefined {
        const deviceMap = [
            {className: DeviceTypes.Smartphone, check: Device.isSmartphone},
            {className: DeviceTypes.Tablet, check: Device.isTablet},
            {className: DeviceTypes.Desktop, check: Device.isDesktop},
        ];

        for (let device of deviceMap) {
            if (device.check) {
                return device.className;
            }
        }

        return undefined;
    }

    public static get currentOrientation() {
        return this.currentWidth > this.currentHeight ? DeviceOrientation.Landscape : DeviceOrientation.Portrait;
    }

    public static get isSmartphone() {
        return this.currentWidth < DeviceBreakPoints.Smartphone;
    }

    public static get isNotSmartphone() {
        return !Device.isSmartphone;
    }

    public static get isTablet() {
        return this.currentWidth >= DeviceBreakPoints.Smartphone && this.currentWidth <= DeviceBreakPoints.Tablet;
    }

    public static get isDesktop() {
        return this.currentWidth >= DeviceBreakPoints.Tablet;
    }

    public static get isTouch() {
        return navigator.userAgent.toLowerCase().match(/mobile/i);
    }

    public static inject(el: HTMLElement) {
        const cls: string[] = [this.getOS()!, this.getBrowser()!, this.currentDevice!, this.currentOrientation];
        const filterNonSet = (object: object) => {
            return Object.values(object).filter((className: string) => !cls.includes(className));
        };

        // remove previous set classes, that will not be (re-)set now
        el.classList.remove(
            ...[...filterNonSet(DeviceOS), ...filterNonSet(DeviceTypes), ...filterNonSet(DeviceOrientation)]
        );

        // add the current ones
        el.classList.add(...cls);
    }

    public static get isDarkMode() {
        return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    }

    public static getOS(): DeviceOS | undefined {
        const os = [
            {regex: /Win/, os: DeviceOS.WINDOWS},
            {regex: /Macintosh/, os: DeviceOS.MACOS},
            {regex: /X11/, os: DeviceOS.X11},
            {regex: /Linux/, os: DeviceOS.LINUX},
            {regex: /iPhone|iPad|iPod/, os: DeviceOS.IOS},
            {regex: /android/, os: DeviceOS.ANDROID},
        ];

        for (let check of os) {
            if (check.regex.test(window.navigator.userAgent)) {
                return check.os;
            }
        }

        return undefined;
    }

    public static getBrowser() {
        switch (detect()?.name) {
            case "chrome":
                return Browser.CHROME;
            case "edge":
            case "edge-chromium":
                return Browser.EDGE;
            case "ie":
                return Browser.IE;
            case "safari":
                return Browser.SAFARI;
            case "firefox":
                return Browser.FIREFOX;
        }
    }

    public static get pointerPosition(): { x: number; y: number } {
        return {
            x: pointerEvents.move?.clientX || 0,
            y: pointerEvents.move?.clientY || 0,
        };
    }
}
