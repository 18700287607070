import * as React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { ThemeContextWrapper } from "./components/ThemeContext";
import Init from "./init";

export let appContainer: HTMLElement =
  (document.querySelector("#app") as any) || (document.querySelector("body") as any);

ReactDOM.render(
  <ThemeContextWrapper>
    <React.StrictMode>
      <Init />
    </React.StrictMode>
  </ThemeContextWrapper>,
  appContainer
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
