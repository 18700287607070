import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next, Translation } from "react-i18next";

export const languageMapping: { [k: string]: string } = {
  de: "de_DE",
  en: "en_US",
  bg: "bg_BG",
  pl: "pl_PL",
  ro: "ro_RO",
  ru: "ru_RU",
  uk: "uk_UA",
};

export const languageFlagIconMapping: { [k: string]: string } = {
    de: "de",
    en: "gb",
    bg: "bg",
    pl: "pl",
    ro: "ro",
    ru: "ru",
    uk: "ua",
};

export function initI18n() {
  return i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: "de",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        // Turn off the use of React Suspense
        useSuspense: false,
      },
    });
}

/**
 * The return is a jsx element.
 * If you need a string then use the withTranslation/useTranslation from i18n-next.
 *
 * @param string
 */
export function translate(string: string) {
  return <Translation>{(t) => t(string)}</Translation>;
}
