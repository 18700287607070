import React, {Component, Suspense} from "react";
import AppConfig from "./utils/AppConfig";
import { initI18n } from "./utils/i18n";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "normalize.css";
import "flag-icons/css/flag-icons.min.css";
import "./index.css";
import { Device } from "./utils/Device";
import { appContainer } from "./index";
import moment from "moment-timezone";
const App = React.lazy(() => import("./components/App/App"));

// Add FontAwesome Libs
library.add(fas, fal);
moment.tz.setDefault("Europe/Berlin");

type States = {
  initialized: boolean;
};

export default class Init extends Component<{}, States> {
  constructor(props: {}) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    let i18nPromise = initI18n();
    let configPromise = AppConfig.init();
    Promise.all([i18nPromise, configPromise]).then(() => {
      setTimeout(() => {
        Device.inject(appContainer as HTMLElement);
        this.setState({ initialized: true });
      }, 500);
    });
  }

  render() {
    const { initialized } = this.state;
    return <Suspense fallback={this.renderLoading()}>{initialized ?<App /> : this.renderLoading()} </Suspense>;
  }

  // this use custom loading instead of Loading component because we still haven't initialized the app yet-
  renderLoading() {
    return (
      <div
        className={
          "flex justify-center items-center w-full h-full text-2xl bg-white text-slate-500 absolute top-0 left-0 right-0"
        }
      >
        <FontAwesomeIcon className={"mr-2"} spin={true} icon={["fal", "spinner"]} />
        <span>Daten werden geladen...</span>
      </div>
    );
  }
}
