import React, { useEffect } from "react";
import { ThemeContext, themes } from "./ThemeContext";
import { Device } from "../../utils/Device";
import { appContainer } from "../../index";
import { useLocalStorage } from "usehooks-ts";

export function ThemeContextWrapper(props: any) {
  const [theme, setTheme] = useLocalStorage<string>("theme", Device.isDarkMode ? themes.light : themes.light);

  function changeTheme(theme: string) {
    setTheme(theme);
  }

  useEffect(() => {
    switch (theme) {
      case themes.dark:
        appContainer.classList.add("dark-mode");
        break;
      case themes.light:
      default:
        appContainer.classList.remove("dark-mode");
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, toggleTheme: changeTheme }}>{props.children}</ThemeContext.Provider>
  );
}
