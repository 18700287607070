type ConfigJson = {};

type EnvVars = {
    API_ENTRYPOINT?: string;
};

let _config: ConfigJson & { env: EnvVars };

class AppConfig {
    get get() {
        if (!_config) {
            throw new Error("AppConfig must be initialized first with: AppConfig.init()");
        }
        return _config;
    }

    async init() {
        if (!_config) {
            _config = {
                env: await this.getEnvs()
            };
        }
    }

    private async getEnvs() {
        const envSources: EnvVars[] = [process.env as EnvVars];
        if (process.env.NODE_ENV !== "development") {
            envSources.push(((await AppConfig.fetch("/config")) as unknown as EnvVars) || {});
        }

        const match = /^(REACT_)?APP_/;

        return Object.entries<string>(Object.assign({}, ...envSources))
            .filter((entry) => entry[0].match(match))
            .map((entry) => {
                let [key, value] = entry;
                key = key.replace(match, "");

                return [key, value || ""];
            })
            .reduce((envs, entry) => {
                envs[entry[0]] = entry[1];
                return envs;
            }, {} as { [k: string]: string });
    }

    private static fetch(url: string) {
        let init: RequestInit = {
            method: "get",
            mode: "cors",
            credentials: "include"
        };

        return (() => {
            return fetch(url, init)
                .then((response) => {
                    return response.json();
                })
                .then((json: JSON) => {
                    let response = json as any;
                    if (response.code >= 400) {
                        return;
                    }
                    return json;
                })
                .catch((reason: any) => {
                });
        })();
    }

}

export default new AppConfig();
